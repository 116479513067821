<template>
    <div class="edrea_tm_section" id="infos">
      <div class="section_inner">
        <div class="edrea_tm_about">
          <div>
            <div>
                <h3>Mentions Légales et Conditions Générales d'Utilisation</h3>
            </div><br>
            <div class="text" style="color: #000;">
              <p>
                <strong>Assur’Care</strong> by <strong>Assuraf</strong> : l’assurance Santé 100% digitale dans votre poche qui vous facilite la vie.
                Moins de paperasse, plus de visibilité : tout se fait en ligne.
              </p>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                Nulla nostrum reprehenderit est asperiores, quasi tenetur cumque, 
                et iure ad sapiente amet provident corporis. Eveniet obcaecati qui 
                cum tempore unde recusandae.
              </p>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                Nulla nostrum reprehenderit est asperiores, quasi tenetur cumque, 
                et iure ad sapiente amet provident corporis. Eveniet obcaecati qui 
                cum tempore unde recusandae.
              </p>
              <p>
                <a href="#" style="color:#069299!important;text-decoration: none;">first_link</a>
              </p> <br>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                Nulla nostrum reprehenderit est asperiores, quasi tenetur cumque, 
                et iure ad sapiente amet provident corporis. Eveniet obcaecati qui 
                cum tempore unde recusandae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
                Maiores soluta nam, doloribus voluptatibus aut ipsum quisquam sed earum labore quo commodi 
                repudiandae voluptates facilis rem non error doloremque repellat similique! Lorem ipsum dolor,
                 sit amet consectetur adipisicing elit. Magni assumenda repudiandae voluptate voluptates, optio del
                 eniti ducimus possimus, perspiciatis placeat nam, sit quis laborum atque non ratione inventore quod 
                 aliquid fuga?
              </p> <br>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                Nulla nostrum reprehenderit est asperiores, quasi tenetur cumque, 
                et iure ad sapiente amet provident corporis. Eveniet obcaecati qui 
                cum tempore unde recusandae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
                Maiores soluta nam, doloribus voluptatibus aut ipsum quisquam sed earum labore quo commodi 
                repudiandae voluptates facilis rem non error doloremque repellat similique! Lorem ipsum dolor,
                 sit amet consectetur adipisicing elit. Magni assumenda repudiandae voluptate voluptates, optio del
                 eniti ducimus possimus, perspiciatis placeat nam, sit quis laborum atque non ratione inventore quod 
                 aliquid fuga?
              </p> <br>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                Nulla nostrum reprehenderit est asperiores, quasi tenetur cumque, 
                et iure ad sapiente amet provident corporis. Eveniet obcaecati qui 
                cum tempore unde recusandae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
                Maiores soluta nam, doloribus voluptatibus aut ipsum quisquam sed earum labore quo commodi 
                repudiandae voluptates facilis rem non error doloremque repellat similique! Lorem ipsum dolor,
                 sit amet consectetur adipisicing elit. Magni assumenda repudiandae voluptate voluptates, optio del
                 eniti ducimus possimus, perspiciatis placeat nam, sit quis laborum atque non ratione inventore quod 
                 aliquid fuga?
              </p> <br>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                Nulla nostrum reprehenderit est asperiores, quasi tenetur cumque, 
                et iure ad sapiente amet provident corporis. Eveniet obcaecati qui 
                cum tempore unde recusandae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
                Maiores soluta nam, doloribus voluptatibus aut ipsum quisquam sed earum labore quo commodi 
                repudiandae voluptates facilis rem non error doloremque repellat similique! Lorem ipsum dolor,
                 sit amet consectetur adipisicing elit. Magni assumenda repudiandae voluptate voluptates, optio del
                 eniti ducimus possimus, perspiciatis placeat nam, sit quis laborum atque non ratione inventore quod 
                 aliquid fuga?
              </p> <br>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                Nulla nostrum reprehenderit est asperiores, quasi tenetur cumque, 
                et iure ad sapiente amet provident corporis. Eveniet obcaecati qui 
                cum tempore unde recusandae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
                Maiores soluta nam, doloribus voluptatibus aut ipsum quisquam sed earum labore quo commodi 
                repudiandae voluptates facilis rem non error doloremque repellat similique! Lorem ipsum dolor,
                 sit amet consectetur adipisicing elit. Magni assumenda repudiandae voluptate voluptates, optio del
                 eniti ducimus possimus, perspiciatis placeat nam, sit quis laborum atque non ratione inventore quod 
                 aliquid fuga?
              </p> <br>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                Nulla nostrum reprehenderit est asperiores, quasi tenetur cumque, 
                et iure ad sapiente amet provident corporis. Eveniet obcaecati qui 
                cum tempore unde recusandae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
                Maiores soluta nam, doloribus voluptatibus aut ipsum quisquam sed earum labore quo commodi 
                repudiandae voluptates facilis rem non error doloremque repellat similique! Lorem ipsum dolor,
                 sit amet consectetur adipisicing elit. Magni assumenda repudiandae voluptate voluptates, optio del
                 eniti ducimus possimus, perspiciatis placeat nam, sit quis laborum atque non ratione inventore quod 
                 aliquid fuga?
              </p> <br>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                Nulla nostrum reprehenderit est asperiores, quasi tenetur cumque, 
                et iure ad sapiente amet provident corporis. Eveniet obcaecati qui 
                cum tempore unde recusandae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
                Maiores soluta nam, doloribus voluptatibus aut ipsum quisquam sed earum labore quo commodi 
                repudiandae voluptates facilis rem non error doloremque repellat similique! Lorem ipsum dolor,
                 sit amet consectetur adipisicing elit. Magni assumenda repudiandae voluptate voluptates, optio del
                 eniti ducimus possimus, perspiciatis placeat nam, sit quis laborum atque non ratione inventore quod 
                 aliquid fuga?
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { navFuntions } from "@/navFuntions";
  
  export default {
    name: `AboutComponent`,
    data() {
      return {
        open: false,
        navFuntions,
      };
    }
  };
  </script>
  
  <style scoped>
  .edrea_tm_modalbox {
    top: -90px;
  }
  </style>
  