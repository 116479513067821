<template>
  <div class="edrea_tm_section animated" id="home">
    <div class="section_inner">
      <div class="edrea_tm_home">
        <h4>Bienvenue sur</h4>
        <h3 class="name" style="color: #ffa409;box-shadow: 9px 12px 18px rgb(0 0 0 / 5%);">Assur'<span class="coloring">Care</span></h3>
        <h3>
         Je me connecte en tant que : 
        </h3>

        <div v-if="isDesktop()">
          <div class="edrea_tm_button transition_link" style="margin-top: 15px;color: grey;">
          <a style="box-shadow: 9px 12px 18px rgb(0 0 0 / 5%);" href="https://assureur.assurcare.africa/" target="_blank"
            >Partenaire</a
          >
          <a href="https://prestataire.assurcare.africa/" target="_blank"
             style="margin-left: 13px;box-shadow: 9px 12px 18px rgb(0 0 0 / 5%);">Prestataire</a
          >
          <a target="_blank"
             style="margin-left: 13px;box-shadow: 9px 12px 18px rgb(0 0 0 / 5%);" href="https://beneficiaire.assurcare.africa/">Bénéficiaire</a
          >
          <a style="margin-left: 13px;box-shadow: 9px 12px 18px rgb(0 0 0 / 5%);" href="https://souscripteur.assurcare.africa/" target="_blank">Souscripteur</a
          >

        </div>

        </div>
        <div v-else>
          <div class="edrea_tm_button transition_link" style="margin-top: 15px;color: grey;">
            <a style="box-shadow: 9px 12px 18px rgb(0 0 0 / 5%);" href="https://assureur.assurcare.africa/" target="_blank"
              >Partenaire</a
            >
          </div>
          <div class="edrea_tm_button transition_link" style="margin-top: 15px;color: grey;">
            <a style="box-shadow: 9px 12px 18px rgb(0 0 0 / 5%);" href="https://prestataire.assurcare.africa/" target="_blank"
              >Prestataire</a
            >
          </div>
          <div class="edrea_tm_button transition_link" style="margin-top: 15px;color: grey;">
            <a style="box-shadow: 9px 12px 18px rgb(0 0 0 / 5%);" href="https://beneficiaire.assurcare.africa/" target="_blank"
              >Bénéficiaire</a
            >
          </div>
          <div class="edrea_tm_button transition_link" style="margin-top: 15px;color: grey;">
            <a style="box-shadow: 9px 12px 18px rgb(0 0 0 / 5%);" href="https://souscripteur.assurcare.africa/" target="_blank"
              >Souscripteur</a
            >
          </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { navFuntions } from "@/navFuntions";

export default {
  name: `HomeComponent`,
  data() {
    return {
      navFuntions,
    };
  },
  methods : {
    isMobile: function () {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    },

    isDesktop: function () {
      if (screen.width > 768) {
        return true
      } else {
        return false
      }
    },
  }
};
</script>
