<template>
  <PreloaderComponent />
  <div
    class="edrea_tm_all_wrap"
    data-magic-cursor="show"
    data-enter="fadeInUp"
    data-exit=""
  >
    <!-- WAVE DOTS -->
    <div class="waves" style="background-color: #FAFAFA;">
      {{ waves }}
    </div>
    <!-- WAVE DOTS -->


    <!-- HEADER -->
    <HeaderComponent />
    <!-- /HEADER -->

    <!-- CONTENT -->
    <div class="edrea_tm_content" style="overflow-y: scroll">
      
      <!-- HOME -->
        <div class="section_inner" >
          <div class="edrea_tm_about">
            <div>
              <div>
                  <h2 class="row justify-content-center">politique de confidentialité</h2>
              </div>
              <br>
              <div class="text" style="color: #000;">
                <div>
                  <h5 style="color:#069299">INFORMATIONS LÉGALES</h5>
                </div>
                <p>
                  <strong>Assur’Care</strong> est une marque de la société <strong>ASSURAF SAS</strong>. La plateforme <a href="https://assurcare.africa" target="_blank" style="color:#069299!important;text-decoration: none;">assurcare.africa</a> est éditée et exploitée par la société <strong>ASSURAF SAS</strong>, société par actions simplifiée de droit Sénégalais au capital de 10.000.000 F CFA, immatriculée au registre de commerce de Dakar sous le numéro SN DKR 2018 B 27531 et dont le siège social est situé à Sicap Sacré cœur 2 – Villa 8634 H – Dakar, Sénégal <strong>ASSURAF SAS</strong> est régie par le code CIMA, a souscrit à une assurance responsabilité civile professionnelle avec les garanties financières règlementaires ; et est immatriculée au registre des intermédiaires en Assurances.
                </p>
                  <br>
    
                  <p>
                    Les plateformes Web et App mobile <a href="https://assurcare.africa" target="_blank" style="color:#069299!important;text-decoration: none;">assurcare.africa</a> sont ainsi les propriétés exclusives d'<strong>Assuraf SAS</strong>. Les informations contenues dans cette plateforme sont non contractuelles, ASSURAF se réserve le droit de supprimer, modifier ou corriger à tout moment le contenu de cette plateforme, ainsi que ses conditions générales d’utilisation. Dans le cadre de la consultation et de l’utilisation des plateformes Web et App mobile de Assur’Care, l’internaute s’engage à respecter les termes détaillés ci-dessous.
                  </p>
                  <br>
                  <div>
                    <h5 style="color:#069299">RÉDACTION ET HÉBERGEUR DU PLATEFORME WEB</h5>
                  </div>
                  <p>Directeur de la publication : <strong>ASSURAF SAS</strong>, Rédacteur en chef : DIRECTION GENERALE, Contact : <a style="color:#069299!important;text-decoration: none;" href="mailto:contact@assuraf.com">contact@assuraf.com</a>.
                    La plateforme assurcare.africa est hébergée sur internet et exploitée par la Société <strong>ASSURAF SAS</strong>
                    </p>
                    <br>
                    <div>
                      <h5 style="color:#069299">PROPRIÉTÉ INTELLECTUELLE</h5>
                    </div>
                    <p>Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner la plateforme et plus généralement tous les éléments reproduits ou utilisés sur la plateforme sont protégés par les lois en vigueur au titre de la propriété intellectuelle.</p>
                    <br>
                    <p>Ils sont la propriété pleine et entière de l’éditeur ou de ses partenaires, sauf mentions particulières. Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans l’accord préalable et écrit de l’éditeur, sont strictement interdites. Le fait pour l’éditeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites. Seule l’utilisation pour un usage privé dans un cercle de famille est autorisée et toute autre utilisation est constitutive de contrefaçon et/ou d’atteinte aux droits voisins, sanctionnées par Code de la propriété intellectuelle. </p> 
                    <br>
                    <p>La reprise de tout ou partie de ce contenu nécessite l’autorisation préalable de l’éditeur ou du titulaire des droits sur ce contenu. La reproduction et/ou représentation et/ou diffusion, même partielle, de la plateforme ou d’un des éléments qui le composent sont interdites sans autorisation préalable d'<strong>ASSURAF</strong> et constitue une contrefaçon au sens du code de la propriété intellectuelle.</p>
                    <br>
                    <p>
                      En effet, le non-respect de cette interdiction constitue une infraction pouvant engager la responsabilité civile et pénale du contrefacteur. Les noms de domaine, les marques de <strong>ASSURAF</strong> et <strong>ASSUR'CARE</strong> sont la propriété exclusive de <strong>ASSURAF SAS</strong>. Toute reproduction et/ou utilisation de ces noms de domaines, marques, logos, seuls ou associés, de quelque manière et à quelque titre que ce soit est interdite. La création de liens hypertextes vers la plateforme ne peut être faite qu’avec l’autorisation préalable de <strong>ASSURAF SAS</strong>.
                    </p>
                    <br>
                    <div>
                      <h5 style="color:#069299">CONTENU ET ACCÉS À LA PLATEFORME</h5>
                    </div>
                    <p><strong>ASSURAF</strong> met en œuvre tous les moyens qui sont en son pouvoir pour que les informations mises à disposition soient complètes, correctes, exhaustives et à jour. Malgré ces efforts, des erreurs peuvent apparaître dans les informations mises à votre disposition. Si les informations fournies sur ou via le portail comportaient des erreurs ou si certaines informations étaient indisponibles sur ou via le portail, nous nous appliquerons à rectifier la situation au plus vite</p>
                    <br>
                    <p>Notre équipe consent également de gros efforts pour éviter autant que possible des interruptions dues à des problèmes techniques. Néanmoins, nous ne pouvons pas garantir que la plateforme sera tout à fait à l'abri d'interruptions ou ne sera pas touchée par d'autres problèmes techniques. Afin de mieux comprendre ce que cela signifie, nous vous donnons toutes les informations ci-dessous en toute sincérité et transparence. Vous disposez d’un droit d’accès, de modification, de rectification et de suppression de toutes données personnelles portées à la connaissance de <a href="https://assurcare.africa" target="_blank" style="color:#069299!important;text-decoration: none;">assurcare.africa</a> lors de l’utilisation de la plateforme
    
                      Ce droit pourra être exercé :
                      </p>
                      <ul style="margin-left: 50px;">
                        <li>Sur <a href="https://assurcare.africa" target="_blank" style="color:#069299!important;text-decoration: none;">assurcare.africa</a> grâce à votre adresse mail et votre mot de passe (créés lors de votre enregistrement)</li>
                        <li>À la rubrique “Nous contacter”</li>
                        <li>Soit par courrier postal à l’adresse suivante à Sacré Coeur 2 – 8634H. Dakar, Sénégal</li>
                        <li>Soit par courriel <a href="mailto:contact@assuraf.com" style="color:#069299!important;text-decoration: none;">(contact@assuraf.com)</a></li>
                        <li>Soit par téléphone (33 864 00 64).</li>
                      </ul>
                      <p>Nous nous efforçons de permettre l’accès à la plateforme 24 heures sur 24, 7 jours sur 7, sauf en cas de force majeure ou d’un événement hors de notre contrôle et sous réserve des éventuelles pannes et interventions de maintenance nécessaire au bon fonctionnement de la plateforme et des services. Par ailleurs, nous pouvons être amenés à interrompre la plateforme ou une partie des services, à tout moment sans préavis, le tout sans droit à indemnités. L’utilisateur reconnaît et accepte que, nous ne sommes pas responsables des interruptions, et des conséquences qui peuvent en découler pour l’utilisateur ou tout tiers.s</p>
                      <br>
                      <div>
                        <h5 style="color:#069299">COLLECTE DE DONNÉES A CARACTÉRE PERSONNEL</h5>
                      </div>
                      <p>Les données à caractère personnel sont des informations vous concernant et concernant d’autres individus. Avant de nous fournir des données à caractère personnel sur quelqu’un d’autre, vous devez informer cette personne de la présente Politique en matière de protection de la vie privée et (si possible) obtenir son accord pour nous communiquer ses données à caractère personnel. 
    
                        Les informations personnelles pouvant être recueillies sur la plateforme sont principalement utilisées par <strong>ASSURAF</strong> pour la gestion des relations avec nos clients et usagers, et le cas échéant pour le traitement de nos interactions et transactions éventuelles. Elles sont enregistrées dans le fichier de clients. La collecte et le traitement de ces données à caractère personnel sont ainsi déclarés auprès de la CDP. Conformément aux dispositions de la Loi N° 2008 - 12 du 25 Janvier 2008 relative à la protection des données à caractère personnel, vous êtes informé(e) qu'<strong>Assuraf</strong> procède à des traitements automatisés de vos données personnelles, notamment lors de votre connexion sur la plateforme ou dans le cadre des formulaires en ligne remplis par vos soins. 
                        </p>
                        <br>
                        <p>Les informations recueillies pourront éventuellement être communiquées à des tiers liés à <strong>ASSURAF</strong> par contrat pour l’exécution de tâches sous-traitées nécessaires à la gestion de votre compte et sans que vous ayez à donner votre autorisation. Vous pouvez vous opposer à ce transfert en manifestant votre refus auprès de notre contact mentionné ci-dessus. En cas d’infraction avérée à des dispositions légales ou réglementaires, ces informations pourront faire l’objet d’une communication sur demande expresse et motivée des autorités judiciaires. </p>
                        <br>
                        <p>Lorsque certaines informations sont obligatoires pour accéder à des fonctionnalités spécifiques du site, <strong>ASSURAF</strong> indiquera ce caractère obligatoire au moment de la saisie des données. Lors de vos démarches dans votre recherche d’assurance, nous avons besoin d’informations personnelles pour établir un devis, un contrat et en cas de validation de ce dernier, pour un suivi constant et personnalisé tout au long de votre engagement avec <strong>ASSURAF</strong>. Les données sont collectées afin d’établir une étude personnalisée sur le produit d'assurance demandée, qui vous sera ensuite communiquée par voie électronique ou physique. Les données à caractère personnel sont obtenues auprès de nombreuses sources, y compris : </p>
                        <ul style="margin-left: 50px;">
                          <li>Formulaires de demande de devis, d’offres particulières et de déclaration de sinistre, et autres formulaires</li>
                          <li>Appels téléphoniques, e-mails, entretiens et autres communications</li>
                          <li>Prestataires de services, courtiers et agents, enquêteurs d’assurance, témoins, professionnels de santé, administrations en charge de la délivrance des permis de conduire et certificats d’immatriculation, organismes d’évaluation du crédit, votre employeur et d’autres tiers</li>
                          <li>Les présentes plateformes Web et App mobile</li>
                          <li>Lors de la création et de la gestion de votre espace personnel (adresse IP, logs de connexion)</li>
                          <li>Les applications logicielles mises à disposition sur ordinateurs, smartphones et autres appareils mobiles (les Applications)</li>
                          <li>Nos pages de médias sociaux, autres contenus, outils et applications des médias sociaux (notre Contenu des médias sociaux).</li>
                        </ul>
                        <p>Nous nous engageons à sécuriser la collecte et le traitement de vos données personnelles, ainsi qu’à les protéger. Sauf indication contraire, les Services de <strong>ASSURAF SAS</strong> ne sont pas destinés à être utilisés par des personnes physiques âgées de moins de dix-huit (18) ans. <strong>ASSURAF</strong> dégage toute responsabilité en cas de contravention à cette disposition. <strong>ASSURAF SAS</strong> ne vendra, ne louera ou ne prêtera ses listes de contacts ou ses listes de clients à aucune société extérieure. Dans chaque cas énoncé ci-dessus, nous collectons vos données avec votre consentement. </p>
                        <br>
                        <p>Nous pouvons utiliser vos données personnelles pour vous faire part de nos offres promotionnelles et de nos nouveaux produits et services par email, SMS, ou sur les réseaux sociaux, lorsque nous sommes légalement en mesure de le faire et que vous ne vous y êtes pas opposé. Dans le cadre d’études de marché et d’enquêtes de satisfaction, <strong>ASSURAF</strong> peut vous contacter directement ou indirectement de manière ponctuelle dans le cadre d’enquêtes de satisfaction dans un souci de développement et d’améliorations de nos services. </p>
                        <br>
                        <p>Dans le cadre d’une candidature, si vous avez postulé à une offre d’emploi de <strong>ASSURAF</strong> via notre site internet ou un site de recherche d’emploi, vos données ou CV font l’objet d’un traitement informatique destiné à la gestion administrative de votre candidature. À des fins d’intérêt légitime, dans le cadre de l’audit interne, nos services chargés du contrôle (commissaire aux comptes, services chargés des procédures internes du contrôle…) certaines de vos données peuvent également être communiquées dans des cas particuliers de contentieux notamment à des auxiliaires de justice, des officiers ministériels et organismes publics habilités à les recevoir, des arbitres, ou bien encore des médiateurs. Les membres du Personnel de <strong>ASSURAF</strong> chargés de traiter les données en raison de leurs fonctions, qualifiés comme étant des « personnes habilitées à y accéder ». </p>
                        <br>
                        <p>Les données personnelles concernées par notre collecte diffèrent en fonction de votre demande d’assurance. Les informations personnelles à fournir dans tous les cas sont : nom, prénom, adresse postale, e-mail, numéro de téléphone mobile ou fixe, date de naissance, justificatifs d’identité. Pour chaque domaine spécialisé, informations supplémentaires vous sont demandées afin que nous puissions vous conseiller au mieux et vous proposer des offres correspondant à votre profil et à vos besoins. </p>
                        <br>
                        <p>Ces données supplémentaires diffèrent en fonction du type d’assurance. Pour l’assurance santé, avec Assur’Care, des information de prestations médicales pourront etre renseignee dans le but de prise en charge et remboursement. Il ne s’agit en aucun cas de dossier medical. Nous limitons la collecte de données et ne vous demandons que les informations utiles pour vous fournir une prestation de qualité. Conformément à la réglementation en vigueur, <strong>ASSURAF</strong> a procédé à la déclaration auprès de la Commission des Données Personnelles (CDP) de la collecte et du traitement des données à caractère personnel effectués via l’App mobile et le site Internet de ASSUR’CARE dont l’URL est la suivante : <a href="https://assurcare.africa" target="_blank" style="color:#069299!important;text-decoration: none;">assurcare.africa</a>.</p>
    
                        <br>
                        <div>
                          <h5 style="color:#069299">COOKIES</h5>
                        </div>
                        <p>Nous vous informons que la navigation sur notre plateforme web est susceptible de provoquer l’installation de cookies sur le terminal de l’utilisateur. Pour information, un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site et à permettre l’élaboration de statistiques de fréquentation. Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services.</p>
                        <br>
                        <div>
                          <h5 style="color:#069299">LIENS HYPERTEXTES</h5>
                        </div>
                        <p>La plateforme peut contenir des liens hypertexte donnant accès à d’autres sites web édités et gérés par des tiers et non par <strong>ASSURAF</strong>. <strong>ASSURAF</strong> ne pourra être tenu responsable directement ou indirectement dans le cas où lesdits sites tiers ne respecteraient pas les dispositions légales. La création de liens hypertexte vers la plateforme ne peut être faite qu’avec l’autorisation écrite et préalable de <strong>ASSURAF</strong>.</p>
                        <br>
                        <div>
                          <h5 style="color:#069299">DROIT D'ACCÉS, DE RECTIFICATION ET DE SUPPRESSION</h5>
                        </div>
                        <p>Conformément à la Loi N° 2008 - 12 du 25 Janvier 2008 relative à la protection des données à caractère personnel, l’internaute utilisateur de la plateforme dispose d’un droit d’accès, de mise à jour, de verrouillage ou de suppression, de rectification et/ou d’opposition des données le concernant qui sont inexactes, incomplètes, périmées. Pour exercer ce droit, il doit s’adresser à <strong>ASSURAF</strong> via son formulaire de contact, par courrier électronique à l’adresse dédiée <a href="mailto:contact@assuraf.com" style="color:#069299!important;text-decoration: none;">(contact@assuraf.com)</a>, en justifiant de son identité.</p>
                        <br>
                        <div>
                          <h5 style="color:#069299">RESPONSABILITÉ EN CAS D'ERREUR</h5>
                        </div>
                        <p><strong>ASSURAF</strong> décline toute responsabilité en cas de difficultés rencontrées lors de l’accès au site ou pour toute défaillance de communication. Il est de votre responsabilité de vérifier que les informations transmises dans les formulaires sont exactes et de rectifier d’éventuelles erreurs. La validité des offres que <a href="https://assurcare.africa" target="_blank" style="color:#069299!important;text-decoration: none;">assurcare.africa</a> vous donne à voir est subordonnée à l’exactitude des réponses que vous fournissez. </p>
                        <br>
                        <p>Il incombe au client de vérifier à réception de son devis ou lors de l’entretien avec <strong>ASSURAF</strong>, la conformité entre sa situation et les données enregistrées lors de l’établissement de l’offre. <strong>ASSURAF</strong> ne saurait être tenue responsable d’un dommage ou d’un virus qui pourraient infecter l’ordinateur de l’internaute ou tout matériel informatique, suite à une utilisation ou à un accès sur la plateforme ou à un téléchargement.</p>
    
                        <br>
                        <div>
                          <h5 style="color:#069299">PLAINTE ET RÉCLAMATION</h5>
                        </div>
                        <p>Nos clients peuvent nous adresser leurs plaintes et leurs réclamations en nous envoyant un mail à <a href="mailto:contact@assuraf.com" style="color:#069299!important;text-decoration: none;">(contact@assuraf.com)</a>. <strong>ASSURAF</strong> s’engage à respecter toute loi applicable et relative aux droits de nos internautes en tant qu’utilisateurs de notre service. Si votre plainte ou votre réclamation n’est pas en lien direct avec notre service ou votre contrat d’assurance, ou qu’elle relève d’un contrat que vous avez souscrit directement avec l’un de nos partenaires, nous vous invitons à prendre directement contact avec lui en suivant les indications contenues.</p>
                        <br>
                        <div>
                          <h5 style="color:#069299">NOTRE SERVICE</h5>
                        </div>
                        <p><strong>ASSURAF</strong> est un intermédiaire en opérations d’assurance dédié aux particuliers et professionnels. La plateforme permet de consulter et souscrire à une assurance qui répond à vos besoins. L’utilisation du site est ainsi soumise aux conditions générales d’utilisation et de souscription. <strong>ASSURAF</strong> constitue un guide dans la recherche d'informations sur les produits d'assurance et leur distribution. </p>
                        <br>
                        <p><strong>ASSURAF</strong> entend vous offrir via ce portail un accès simple, rapide et amélioré aux produits d’assurance santé. Notre rôle consiste à fluidifier le parcours de gestion de l’assurance santé. En utilisant notre service d'assurance en ligne, beaucoup de contraintes des utilisateurs d'assurance sont résolues, avec notamment comme avantage un moyen pratique et flexible de souscrire et gérer toutes vos assurances en gagnant du temps et en accédant à une large offre. Parce que <strong>ASSURAF</strong> à la possibilité de vous aider à choisir l'assurance la plus adaptée à vos besoins avec un choix multiple, et de vous proposer un devis sur mesure et une gestion en ligne.</p>
    
    
               
               
              </div>
            </div>
          </div>
        </div>
      <!-- /HOME -->
    </div>
    <!-- /CONTENT -->

  </div>
</template>

<script>
import PreloaderComponent from "@/components/layout/PreloaderComponent.vue";
import AboutComponent from "../components/AboutComponent.vue";
import InfoComponent from "../components/InfoComponent.vue";
import ContactComponent from "../components/ContactComponent.vue";
import PolicyComponent from "../components/PolicyComponent.vue";
import HomeComponent from "../components/HomeComponent.vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import { dataImage, imgToSVG } from "../utilits";
import { waves } from "../waves";

export default {
  name: `HomePolicy`,
  mounted() {
    waves();
    imgToSVG();
    dataImage();
  },
  components: {
    HomeComponent,
    AboutComponent,
    InfoComponent,
    ContactComponent,
    PolicyComponent,
    HeaderComponent,
    PreloaderComponent
  },
};
</script>

<!-- about chart -->
